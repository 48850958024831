<template>
  <div class="login-container">
    <img :src="require('@/assets/gptconsulta.png')" alt="Logo" class="logo" />
    <h3 class="login-title">Login</h3>
    <form @submit.prevent="onLoginClick" class="space-y-4 form-container">
      <input
        type="text"
        v-model="username"
        placeholder="Usuario"
        class="w-full p-2 border rounded"
        required
      />
      <div class="password-container w-full p-2 border rounded">
        <input
          :type="passwordFieldType"
          v-model="password"
          placeholder="Password"
          class="password-input"
          required
        />
        <span @click="togglePasswordVisibility" class="toggle-password">
          <i :class="passwordFieldType === 'password' ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
        </span>
      </div>

      <button type="submit" class="submit-button" :disabled="isLoading">
        {{ isLoading ? 'Cargando...' : 'ENVIAR' }}
      </button>
    </form>
    <div class="mt-4 text-center">
      <span class="text-gray">Powered by </span>
      <a href="https://integradores.net" target="_blank" class="link-color"> integradores.net </a>
    </div>
    <div v-if="errorMessage" class="error-message text-red-500 mt-4">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
//import { useRouter } from 'vue-router';
import { useRoute, useRouter } from 'vue-router';
import axios from 'axios';
import { useConsultaStore } from '@/stores/consulta';
//import crypto from 'crypto-js';
import { onMounted } from 'vue';
import { useAuthStore } from '../stores/auth';
//import path from 'path';
//import dotenv from 'dotenv';
//dotenv.config();

export default {
  setup() {
    const router = useRouter();
    const route = useRoute();
    const consultaStore = useConsultaStore();
    const username = ref('');
    const password = ref('');
    const passwordFieldType = ref('password');
    const errorMessage = ref('');
    const isLoading = ref(false);
    const authStore = useAuthStore(); //

    // Cargar forzosamente el .env del directorio raíz
    //const rootEnvPath = path.join(__dirname, '../.env');
    //dotenv.config({ path: rootEnvPath });

    onMounted(() => {
      if (route.query.expired === 'true') {
        errorMessage.value = 'Tu sesión ha expirado. Por favor, inicia sesión nuevamente.';
      }
      // Limpiar campos y token
      username.value = '';
      password.value = '';
      localStorage.removeItem('token');
      authStore.clearAuth();
      delete axios.defaults.headers.common['Authorization'];
    });

    const onLoginClick = async () => {
      isLoading.value = true;
      errorMessage.value = '';

      try {
        //const hashedPassword = crypto.MD5(password.value).toString();
        //const response = await axios.post('http://localhost:3000/api/login', {
        //alert('process.env.VUE_APP_API_URL: ' + process.env.VUE_APP_API_URL);
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/login`, {
          username: username.value,
          password: password.value, //hashedPassword,
        });

        if (response.data.success) {
          authStore.setToken(response.data.token);
          localStorage.setItem('token', response.data.token);
          axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
          consultaStore.setLocalConsulta('');
          consultaStore.setSelectedVistaAlias('');
          consultaStore.clearResultado();
          router.push('/consulta');
        } else {
          errorMessage.value = response.data.message || 'Credenciales inválidas...!';
          //alert('Credenciales inválidas');
        }
      } catch (error) {
        console.error('Login error:', error);
        errorMessage.value = 'Ocurrió un error durante el login';
      } finally {
        isLoading.value = false;
      }
    };

    const togglePasswordVisibility = () => {
      passwordFieldType.value = passwordFieldType.value === 'password' ? 'text' : 'password';
    };

    onMounted(() => {
      username.value = '';
      password.value = '';
    });

    return {
      username,
      password,
      passwordFieldType,
      errorMessage,
      isLoading,
      onLoginClick,
      togglePasswordVisibility,
    };
  },
};
</script>

<style scoped>
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 20px;
  box-sizing: border-box;
}

.logo {
  width: 100px;
  margin-bottom: 10px;
}

.form-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 300px;
}

.form-container input,
.form-container button {
  margin-bottom: 60px;
}

.login-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #6b7280;
}

.submit-button {
  width: 100%;
  background-color: #21d0b3;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.submit-button:hover {
  background-color: #208372;
}

.submit-button:active {
  background-color: #21d0b3;
  color: #d1d5db;
}

.error-message {
  color: red;
}

.password-container {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.password-input {
  flex: 1;
  padding-right: 30px;
}

.toggle-password {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.text-gray {
  color: #6b7280; /* Puedes ajustar el código de color gris según tu preferencia */
}
.link-color {
  color: #56b71d; /* Puedes ajustar el código de color gris según tu preferencia */
}
</style>
