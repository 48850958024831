// src/stores/auth.js
import { defineStore } from 'pinia';
import { jwtDecode } from 'jwt-decode';
//import { axios } from '../plugins/axios';
import axios from 'axios';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    token: null,
    dirUrlCliente: null,
  }),
  actions: {
    async renewToken() {
      try {
        const response = await axios.post(
          'http://localhost:3000/api/renew-token',
          {},
          {
            headers: { Authorization: `Bearer ${this.token}` },
          }
        );
        this.setToken(response.data.token);
        return true;
      } catch (error) {
        console.error('Error al renovar el token:', error);
        return false;
      }
    },
    setToken(token) {
      this.token = token;
      const decoded = jwtDecode(token);
      this.dirUrlCliente = decoded.dir_url_cliente;
    },
    setDirUrlCliente(dirUrlCliente) {
      this.dirUrlCliente = dirUrlCliente;
    },
    clearAuth() {
      this.token = null;
      this.dirUrlCliente = null;
    },
    checkTokenExpiration() {
      if (this.token) {
        const decodedToken = jwtDecode(this.token);
        const currentTime = Date.now() / 1000;
        if (decodedToken.exp < currentTime) {
          this.clearAuth();
          return true; // Token expirado
        }
      }
      return false; // Token válido o no existente
    },
  }, // Cierre de la sección de actions
  getters: {
    isAuthenticated: (state) => !!state.token,
    getDirUrlCliente: (state) => state.dirUrlCliente,
  },
});
