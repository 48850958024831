import './assets/tailwind.css';
import { setupErrorHandler } from './utils/errorHandler';
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import VueAxios from 'vue-axios';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCheckDouble } from '@fortawesome/free-solid-svg-icons';

library.add(faCheckDouble);

// Configurar interceptor de Axios
// axios.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     if (error.response && error.response.status === 401) {
//       console.log('Token expirado:', error.response.data);
//       alert('Tu sesión ha expirado. Por favor, inicia sesión nuevamente.');
//       localStorage.removeItem('token'); // Elimina el token
//       router.push('/login'); // Redirige al login
//     }
//     return Promise.reject(error);
//   }
// );

setupErrorHandler(axios);

const app = createApp(App);
const pinia = createPinia();

app.component('font-awesome-icon', FontAwesomeIcon);

app.use(pinia);
app.use(router);
app.use(VueAxios, axios);
app.mount('#app');
