import router from '../router';
import { useAuthStore } from '../stores/auth';

export function setupErrorHandler(axios) {
  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response) {
        const authStore = useAuthStore();
        switch (error.response.status) {
          case 400:
            console.log('Error de solicitud:', error.response.data);
            if (error.response.data.sqlMessage) {
              // Manejo específico para errores SQL
              alert(`Error SQL: ${error.response.data.sqlMessage}`);
            } else {
              alert(error.response.data.message || 'Error en la solicitud');
            }
            break;
          case 401:
            console.log('Token expirado:', error.response.data);
            if (!error.config._retry) {
              error.config._retry = true;
              try {
                await authStore.renewToken();
                return axios(error.config);
              } catch (renewError) {
                authStore.clearAuth();
                router.push({ name: 'Login', query: { expired: 'true' } });
              }
            }
            break;
          case 403:
            console.log('Acceso prohibido:', error.response.data);
            if (
              error.response.data.error &&
              error.response.data.error.code === 'unsupported_country_region_territory'
            ) {
              error.response.data.message =
                'Error de autenticación con OpenAI. Por favor, contacte al administrador del sistema.';
            }
            alert(error.response.data.message || 'Error de autenticación');
            break;
          // ... otros casos ...
        }
      } else if (error.request) {
        // La solicitud fue hecha pero no se recibió respuesta
        console.log('Error de red:', error.request);
        alert('Error de red. Por favor, verifique su conexión a internet.');
      } else {
        // Algo sucedió en la configuración de la solicitud que provocó un error
        console.log('Error:', error.message);
        alert('Error en la configuración de la solicitud.');
      }
      return Promise.reject(error);
    }
  );
}

export function handleSqlError(error) {
  if (error.response && error.response.status === 400) {
    if (error.response.data.sqlMessage) {
      return `Error SQL: ${error.response.data.sqlMessage}`;
    }
  }
  return error.message || 'Error desconocido en la consulta SQL';
}
