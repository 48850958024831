import { createRouter, createWebHistory } from 'vue-router';
import LoginComponent from '../components/LoginComponent.vue';
import ConsultaComponent from '../components/ConsultaComponent.vue';
import EjemplosComponent from '../components/EjemplosComponent.vue';
import CamposViewComponent from '@/components/CamposViewComponent.vue';
import ConsumoComponent from '../components/ConsumoComponent.vue'; // Nuevo componente para mostrar el consumo
import { useAuthStore } from '../stores/auth';

const routes = [
  { path: '/', component: LoginComponent },
  { path: '/consulta', component: ConsultaComponent, name: 'consulta' },
  {
    path: '/Login',
    component: LoginComponent,
    name: 'LoginComponent',
  },
  {
    path: '/ejemplos',
    component: EjemplosComponent,
    name: 'EjemplosComponent',
  },
  {
    path: '/campos',
    component: CamposViewComponent,
    name: 'CamposViewComponent',
  },
  {
    path: '/consumo',
    component: ConsumoComponent,
    name: 'ConsumoComponent',
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();
  const publicPages = ['/'];
  const authRequired = !publicPages.includes(to.path);

  if (authRequired && !authStore.isAuthenticated) {
    alert('Tu sesión ha expirado. Por favor, inicia sesión nuevamente.');
    next({ path: '/', query: { expired: 'true' } });
  } else {
    next();
  }
});

export default router;
