<template>
  <div class="campos-container">
    <img :src="require('@/assets/gptconsulta.png')" alt="Logo" class="logo" />
    <h3 class="titulo">Campos de la Vista</h3>
    <div class="scrollable-campos">
      <div v-for="(campo, index) in campos" :key="index" class="campo-row">
        <div class="campo-text">{{ campo.alias_campo }}</div>
        <button @click="usarCampo(campo.campo)" class="btn-usar">✅</button>
      </div>
    </div>
    <button @click="volver" class="btn-volver">VOLVER</button>
  </div>
</template>

<script>
import { useConsultaStore } from '@/stores/consulta';
import { computed, ref, onMounted } from 'vue';
import axios from 'axios';
import { useRouter, useRoute } from 'vue-router';
import { useAuthStore } from '@/stores/auth';

export default {
  name: 'CamposViewComponent',
  setup() {
    const consultaStore = useConsultaStore();
    const authStore = useAuthStore();
    const router = useRouter();
    const route = useRoute();

    const diccionario_datos = ref(null);
    const campos = ref([]);

    const localConsulta = computed({
      get: () => consultaStore.localConsulta,
      set: (value) => consultaStore.setLocalConsulta(value),
    });

    const loadDiccionario = async () => {
      try {
        //const response = await axios.get('http://localhost:3000/api/diccionario', {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/diccionario`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        diccionario_datos.value = response.data;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          alert('Tu sesión ha expirado. Por favor, inicia sesión nuevamente.');
          authStore.clearAuth();
          router.push({ name: 'Login', query: { expired: 'true' } });
        } else {
          console.error('Error al cargar el diccionario de datos:', error);
        }
      }
    };

    const usarCampo = (campo) => {
      consultaStore.appendToLocalConsulta(campo);
      router.push('/consulta');
    };

    const volver = () => {
      router.push('/consulta');
    };

    onMounted(async () => {
      if (!authStore.isAuthenticated) {
        alert('Tu sesión ha expirado. Por favor, inicia sesión nuevamente.');
        router.push({ name: 'Login', query: { expired: 'true' } });
        return;
      }
      //alert(`Vista Alias al montar: ${route.query.vistaAlias}`);
      //alert(`Todas las query params: ${JSON.stringify(route.query)}`);
      await loadDiccionario();
      const vistaAlias = route.query.vistaAlias;
      if (diccionario_datos.value) {
        const vista = diccionario_datos.value.vistas.find(
          (v) => v.vista_alias_contexto === vistaAlias
        );
        if (vista) {
          campos.value = vista.prompt_parte2_diccionario_datos;
        }
      }
    });

    return {
      localConsulta,
      consultaStore,
      diccionario_datos,
      campos,
      usarCampo,
      volver,
    };
  },
};
</script>

<style scoped>
.titulo {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
  text-align: center;
  color: #6b7280;
}
.campos-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 2px;
  box-sizing: border-box;
}

.logo {
  width: 100px;
  margin-bottom: 1px;
}

.scrollable-campos {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 300px;
  overflow-y: auto;
  width: 100%;
  padding: 2px;
  box-sizing: border-box;
}

.campo-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 500px;
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 10px;
}

.campo-text {
  flex: 1;
  margin-right: 10px;
}

.btn-usar {
  /*background-color: #21d0b3;*/
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-usar:hover {
  background-color: #208372;
}

.btn-volver {
  background-color: #6b7280;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 20px;
}

.btn-volver:hover {
  background-color: #4b5563;
}
</style>
