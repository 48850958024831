<template>
  <div class="consumo-container">
    <h2>Consumo de Consultas</h2>
    <div v-if="loading">Cargando información de consumo...</div>
    <div v-else>
      <p>Consultas mensuales: {{ consultasMensuales }}</p>
      <p>Consultas realizadas: {{ consultasRealizadas }}</p>
      <p>Consultas disponibles: {{ consultasDisponibles }}</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ConsumoComponent',
  data() {
    return {
      consultasMensuales: 0,
      consultasRealizadas: 0,
      consultasDisponibles: 0,
      loading: true,
    };
  },
  mounted() {
    this.obtenerConsumo();
  },
  methods: {
    async obtenerConsumo() {
      try {
        const response = await axios.get('http://localhost:3000/api/consumo', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        this.consultasMensuales = response.data.consultasMensuales;
        this.consultasRealizadas = response.data.consultasRealizadas;
        this.consultasDisponibles = response.data.consultasDisponibles;
      } catch (error) {
        console.error('Error al obtener el consumo:', error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.consumo-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}
</style>
