import { defineStore } from 'pinia';

export const useConsultaStore = defineStore('consulta', {
  state: () => ({
    localConsulta: '',
    selectedVistaAlias: '', // Añadir esta línea
    resultado: '', // Añadir esta líne
  }),
  getters: {
    hasSelectedVistaAlias: (state) => !!state.selectedVistaAlias,
  },
  actions: {
    setLocalConsulta(value) {
      this.localConsulta = value;
    },
    appendToLocalConsulta(value) {
      this.localConsulta += ' ' + value;
    },
    setSelectedVistaAlias(value) {
      this.selectedVistaAlias = value;
    },
    setResultado(value) {
      this.resultado = value;
    },
    clearResultado() {
      this.resultado = '';
    },
    updateConsultas(disponibles, mensuales) {
      this.consultasDisponibles = disponibles;
      this.consultasMensuales = mensuales;
    },
  },
});
