<template>
  <div class="consulta-container">
    <img :src="require('@/assets/gptconsulta.png')" alt="Logo" class="logo" />
    <div class="mt-4 text-center">
      <span class="text-gray">Powered by </span>
      <a href="https://integradores.net" target="_blank" class="link-color"> integradores.net </a>
    </div>
    <div class="consumo-info">
      Consultas disponibles: {{ consultasDisponibles }}/{{ consultasMensuales }}
    </div>
    <div class="links-container">
      <router-link :to="{ path: '/ejemplos', query: { vista: selectedVista } }" class="link"
        >Favoritos</router-link
      >
      <router-link :to="{ path: '/campos', query: { vistaAlias: selectedVistaAlias } }" class="link"
        >Campos
      </router-link>
      <button @click="onGrabarClick" class="btn-grabar">⭐</button>
    </div>
    <form @submit.prevent="onEnviarClick" class="space-y-4 form-container">
      <select
        v-model="selectedVistaAlias"
        @change="updateVista"
        class="dropdown w-full p-2 border rounded block"
      >
        <option
          v-for="vista in vistas"
          :key="vista.nombre_vista"
          :value="vista.vista_alias_contexto"
        >
          {{ vista.vista_alias_contexto }}
        </option>
      </select>
      <div class="consulta-wrapper">
        <textarea
          v-model="localConsulta"
          placeholder="Consulta"
          class="consulta-textarea w-full p-2 border rounded block extra-small-font"
          required
          ref="consultaTextarea"
        ></textarea>
      </div>
      <textarea
        v-model="resultado"
        placeholder="Resultado"
        class="w-full p-2 border rounded block extra-small-font"
        rows="6"
        readonly
      ></textarea>
      <button type="submit" class="btn-enviar" :disabled="loading">ENVIAR</button>
    </form>
    <div class="button-spinner-wrapper">
      <div class="button-container">
        <button @click="onSalirClick" class="btn-salir">SALIR</button>
        <div v-if="loading" class="spinner-container">
          <div class="loading-spinner">
            <img src="@/assets/spinner_adn.gif" alt="Loading..." />
          </div>
        </div>
      </div>
    </div>
    <div v-if="mostrarDialogo" class="dialog-overlay">
      <div class="dialog">
        <h3>¿Desea salir de la aplicación?</h3>
        <button @click="confirmarSalir" class="btn-confirm">Sí</button>
        <button @click="cancelarSalir" class="btn-confirm">No</button>
      </div>
    </div>
    <div v-if="mensaje" class="popup">
      <p>{{ mensaje }}</p>
      <button @click="cerrarMensaje">Cerrar</button>
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue';

import axios from 'axios';
import { onMounted } from 'vue';
//import { diccionario_datos } from '@/assets/db_diccionario.js';
//import { useConsultaStore } from '@/stores/consulta';
//import { useConsultaStore } from '@/stores/consultaStore';
//import useConsultaStore from '@/stores/consulta';
import { useConsultaStore } from '@/stores/consulta';
//import { computed } from "vue";
//import { storeToRefs } from 'pinia';
//import jwt_decode from 'jwt-decode';
import { jwtDecode } from 'jwt-decode';
import { computed } from 'vue';
import { nextTick } from 'vue';
//import jwtDecode from 'jwt-decode';
import { useAuthStore } from '../stores/auth';
import { useRouter } from 'vue-router';

const selectedVistaAlias = ref('');
const vistas = ref([]);
const consultasMensuales = ref(0);
const consultasRealizadas = ref(0);
const consultasDisponibles = ref(0);
//const error = ref(null);

/*
const obtenerConsumo = async () => {
  try {
    //const response = await axios.get('http://localhost:3000/api/consumo', {
    const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/consumo`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    consultasDisponibles.value = response.data.consultasDisponibles;
    consultasMensuales.value = response.data.consultasMensuales;
  } catch (err) {
    //console.error('Error al obtener el consumo:', err);
    alert('Error al obtener el consumo o Suscripción expirada');
    error.value = 'Error al obtener el consumo o Suscripción expirada';
    router.push({ name: 'Login', query: { expired: 'true' } });
  }
};*/

/*
const actualizarConsumo = async () => {
  try {
    await axios.post(
      'http://localhost:3000/api/consumo',
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    );
    await obtenerConsumo();
  } catch (error) {
    console.error('Error al actualizar el consumo:', error);
  }
};*/

watch(
  vistas,
  (newVistas) => {
    if (newVistas.length > 0 && !selectedVistaAlias.value) {
      selectedVistaAlias.value = newVistas[0].vista_alias_contexto;
    }
  },
  { immediate: true }
);

//import CampoVista from "./CamposViewComponent.vue";
//import ejemplos from "@/assets/ejemplos.json";

export default {
  name: 'ConsultaComponent',
  mounted() {
    //this.showTokenInfo();
  },

  setup() {
    const consultaStore = useConsultaStore();
    const diccionario_datos = ref(null);
    const vistas = ref([]);
    const selectedVistaAlias = ref('');
    const selectedVista = ref('');
    const router = useRouter();
    const authStore = useAuthStore();

    const resultado = ref(null);
    const error = ref(null);

    // Observa cambios en consultaStore.resultado
    watch(
      () => consultaStore.resultado,
      (newValue) => {
        resultado.value = newValue;
      }
    );

    const checkTokenExpiration = () => {
      if (!authStore.isAuthenticated) {
        alert('Tu sesión ha expirado. Por favor, inicia sesión nuevamente.');
        router.push({ name: 'Login', query: { expired: 'true' } });
      }
    };

    const obtenerConsumo = async () => {
      try {
        //const response = await axios.get('http://localhost:3000/api/consumo', {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/consumo`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        consultasDisponibles.value = response.data.consultasDisponibles;
        consultasMensuales.value = response.data.consultasMensuales;
      } catch (err) {
        //console.error('Error al obtener el consumo:', err);
        alert('Error al obtener el consumo o Suscripción expirada');
        error.value = 'Error al obtener el consumo o Suscripción expirada';
        //router.push({ name: 'Login', query: { expired: 'false' } });
        router.push('/Login');
      }
    };

    const localConsulta = computed({
      get: () => consultaStore.localConsulta,
      set: (value) => consultaStore.setLocalConsulta(value),
    });

    const ejemplos = ref([]);

    const cargarEjemplos = async () => {
      try {
        //const response = await axios.get('http://localhost:3000/api/ejemplos', {
        const response = await axios.get(`/api/ejemplos`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        ejemplos.value = response.data;
      } catch (error) {
        console.error('Error al cargar los ejemplos:', error);
      }
    };

    //onMounted(cargarEjemplos);
    // Watch para actualizar selectedVistaAlias cuando el valor en el store cambie
    // Computed para el selectedVistaAlias del store
    const storeSelectedVistaAlias = computed(() => consultaStore.selectedVistaAlias);

    watch(storeSelectedVistaAlias, (newVal) => {
      selectedVistaAlias.value = newVal;
    });

    const loadDiccionario = async () => {
      try {
        //alert('Iniciando loadDiccionario');
        //const response = await axios.get('/api/diccionario');
        //alert(process.env.VUE_APP_API_URL);
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/diccionario`);
        //alert('Respuesta recibida', response.data);
        diccionario_datos.value = response.data;
        vistas.value = diccionario_datos.value.vistas;

        //alert(`Vistas cargadas: ${JSON.stringify(vistas.value)}`);

        // Recuperar el selectedVistaAlias del store
        const storeVistaAlias = consultaStore.selectedVistaAlias;
        //alert(`Vista alias en el store: ${storeVistaAlias}`);

        selectedVistaAlias.value = storeVistaAlias || vistas.value[0].vista_alias_contexto;
        //alert(`selectedVistaAlias asignado: ${selectedVistaAlias.value}`);

        // Encontrar la vista correspondiente y asignarla a selectedVista
        const vista = vistas.value.find((v) => v.vista_alias_contexto === selectedVistaAlias.value);
        if (vista) {
          selectedVista.value = vista.nombre_vista;
          //alert(`Vista encontrada y asignada: ${selectedVista.value}`);
        } else {
          // Si no se encuentra la vista, seleccionar la primera
          selectedVista.value = vistas.value[0].nombre_vista;
          selectedVistaAlias.value = vistas.value[0].vista_alias_contexto;
          // alert(`Vista no encontrada, seleccionando la primera: ${selectedVista.value}`);
        }

        // Asegurar que el store esté actualizado
        consultaStore.setSelectedVistaAlias(selectedVistaAlias.value);
        //alert(`Store actualizado con: ${consultaStore.selectedVistaAlias}`);

        await nextTick();
        //alert('loadDiccionario completado');
      } catch (error) {
        console.error('Error al cargar el diccionario:', error);
        //alert(`Error en loadDiccionario: ${error.message}`);
      }
    };

    onMounted(() => {
      //alert('111');
      //console.log('222');
      loadDiccionario();
      checkTokenExpiration();
      resultado.value = consultaStore.resultado;
      //resultado.value = '';
      obtenerConsumo();
    });

    const appendToLocalConsulta = (text) => {
      consultaStore.appendToLocalConsulta(text);
    };

    const clearLocalConsulta = () => {
      consultaStore.clearLocalConsulta();
    };

    return {
      ejemplos,
      cargarEjemplos,
      localConsulta,
      appendToLocalConsulta,
      clearLocalConsulta,
      diccionario_datos,
      vistas,
      //selectedVistaAlias: [],
      selectedVista,
      selectedVistaAlias,
      consultaStore,
      resultado,
      consultasMensuales,
      consultasRealizadas,
      consultasDisponibles,
    };
  },
  computed: {
    localconsulta: {
      get() {
        return this.consultaStore.localConsulta;
      },
      set(value) {
        this.consultaStore.appendToLocalConsulta(value);
      },
    },
  },

  data() {
    return {
      loading: false,
      mostrarDialogo: false,
      mensaje: '',
    };
  },
  watch: {
    $route(to) {
      if (to.query.consulta) {
        this.localConsulta = to.query.consulta;
        this.$nextTick(() => {
          const textarea = this.$refs.consultaTextarea;
          if (textarea) {
            textarea.focus();
            textarea.setSelectionRange(this.localConsulta.length, this.localConsulta.length);
          }
        });
      }
    },
    consulta(newConsulta) {
      this.localConsulta = newConsulta;
      this.$nextTick(() => {
        const textarea = this.$refs.consultaTextarea;
        if (textarea) {
          textarea.focus();
          textarea.setSelectionRange(this.localConsulta.length, this.localConsulta.length);
        }
      });
    },

    mounted() {
      if (this.$route.query.consulta) {
        this.localConsulta += ` ${this.$route.query.consulta}`;
      }
    },
  },

  methods: {
    showTokenInfo() {
      const token = localStorage.getItem('token');
      if (token) {
        console.log('Token:', token);
        //alert('Token: ' + token);

        try {
          const decoded = jwtDecode(token); // Usa jwtDecode aquí
          console.log('Payload decodificado:', decoded);
          //alert('Payload decodificado: ' + JSON.stringify(decoded, null, 2));
        } catch (error) {
          console.error('Error al decodificar el token:', error);
          alert('Error al decodificar el token: ' + error.message);
        }
      } else {
        console.log('No se encontró token en el almacenamiento local');
        alert('No se encontró token en el almacenamiento local');
      }
    },
    updateVista() {
      const selectedVistaObj = this.vistas.find(
        (v) => v.vista_alias_contexto === this.selectedVistaAlias
      );
      //alert(`updateVista Valor antes en el store: ${this.consultaStore.selectedVistaAlias}`);
      this.selectedVista = selectedVistaObj
        ? selectedVistaObj.nombre_vista
        : this.vistas[0].nombre_vista;
      //alert('UPDATE Vista seleccionada: ' + this.selectedVistaAlias);
      this.consultaStore.setSelectedVistaAlias(this.selectedVistaAlias);
      //alert(`updateVista Valor establecido en el store: ${this.consultaStore.selectedVistaAlias}`);
    },
    async onEnviarClick() {
      this.loading = true;
      await nextTick();
      const data = {
        query: this.localConsulta,
        vista: this.selectedVista,
      };
      try {
        //const response = await axios.post('http://localhost:3000/api/consulta', data);
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/consulta`, data);
        let temp_localConsulta = this.localConsulta.length > 120 ? '' : this.localConsulta + ':\n';
        if (response.data.results && response.data.results.length > 0) {
          this.resultado = `<━━━━━━━━━━━━━━━>\n${temp_localConsulta}${response.data.results
            .map((row) => Object.values(row).join(', '))
            .join('\n')}\n${this.resultado}`;
          //this.resultado = `${this.localConsulta};\n\n${this.resultado}`;
          //this.resultado = `${this.localConsulta}:\n${this.resultado}`;

          this.consultaStore.setResultado(this.resultado); // Actualizar resultado en el store
          //await actualizarConsumo();
          // En su lugar, actualizar directamente las variables locales:
          consultasDisponibles.value--;
          // Asegúrate de que consultasRealizadas esté definida y sea reactiva
          if (consultasRealizadas.value) {
            consultasRealizadas.value++;
          }

          // Dentro de la función onSubmit, después de una consulta exitosa:
          this.consultaStore.updateConsultas(
            response.data.consultas_disponibles,
            response.data.consultas_mensuales
          );
        } else {
          this.resultado = 'No se encontraron resultados.\n' + this.resultado;
        }
      } catch (error) {
        console.error('Error sending consulta:', error);
      } finally {
        this.loading = false;
      }
    },
    onSalirClick() {
      this.mostrarDialogo = true;
    },
    confirmarSalir() {
      this.$router.push('/');
    },
    cancelarSalir() {
      this.mostrarDialogo = false;
    },

    async onGrabarClick() {
      //alert('a1a1');
      if (!this.localConsulta.trim()) {
        alert('Por favor, ingrese una consulta antes de grabar.');
        return;
      }

      try {
        //alert('11');
        //const response = await axios.get('http://localhost:3000/api/ejemplos', {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/ejemplos`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        let ejemplos = response.data;

        // Asegurarse de que ejemplos sea un array de objetos válidos
        ejemplos = ejemplos.filter((e) => typeof e === 'object' && e.nombre_vista && e.ejemplo);

        // Verificar si el ejemplo ya existe
        const ejemploExistente = ejemplos.find((e) => e.ejemplo === this.localConsulta.trim());
        if (ejemploExistente) {
          alert('Esa Consulta ya existe en Favoritos.');
          return;
        }

        const nuevoEjemplo = {
          nombre_vista: this.selectedVista, // Asegúrate de que esta variable contenga el nombre correcto de la vista
          ejemplo: this.localConsulta.trim(),
        };

        ejemplos.push(nuevoEjemplo);

        await axios.post(
          //'http://localhost:3000/api/actualizar-ejemplos',
          `${process.env.VUE_APP_API_URL}/api/actualizar-ejemplos`,
          { ejemplos },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );

        alert('Consulta guardada en Favoritos');
        // Opcionalmente, puedes limpiar el campo de consulta después de guardar
        // this.localConsulta = '';
      } catch (error) {
        console.error('Error al guardar el Favoritos:', error);
        alert('Error al guardar el Favoritos');
      }
    },
  },
};
</script>

<style scoped>
.consulta-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 20px;
  box-sizing: border-box;
}

.form-container {
  display: flex;
  flex-direction: column;
  width: 100%; /* Ajusta el ancho según tus necesidades */
  max-width: 300px; /* Puedes ajustar esto para limitar el ancho del formulario */
}
.form-container input,
.form-container textarea,
.form-container button {
  margin-bottom: 10px;
}

.form-container button:last-child {
  margin-bottom: 0; /* Para evitar margen adicional en el último botón */
}
.logo {
  width: 100px;
  margin-bottom: 1px;
}
.titulo {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
  color: #6b7280;
}
.ver-ejemplos {
  color: #3b82f6;
  text-decoration: underline;
  margin-bottom: 20px;
}
.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.dialog {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}
a.text-blue-500 {
  color: #3b82f6;
}
.btn-enviar {
  width: 100%;
  background-color: #21d0b3; /* azul */
  color: white;
  padding: 0.5rem;
  border-radius: 0.25rem;
  text-align: center;
  cursor: pointer;
}
.btn-enviar:hover {
  background-color: #208372; /* azul más oscuro al pasar el ratón */
}
.btn-salir {
  background-color: #6b7280; /* gris */
  color: white;
  padding: 0.5rem;
  border-radius: 0.25rem;
  text-align: center;
  cursor: pointer;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  min-width: 100px;
  display: inline-block;
}
.btn-salir:hover {
  background-color: #4b5563; /* gris más oscuro al pasar el ratón */
}
.button-container {
  position: relative; /* Añade esta línea */
}

.button-spinner-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.button-container {
  position: relative;
  display: inline-flex;
  align-items: center;
}
.spinner-container {
  position: absolute;
  left: 100%; /* Coloca el spinner justo después del botón */
  margin-left: 10px; /* Espacio entre el botón y el spinner */
  width: 50px;
  height: 50px;
}

.loading-spinner {
  width: 100%;
  height: 100%;
}

.loading-spinner img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.btn-grabar {
  background-color: #21d0b3; /* verde */
  color: white;
  padding: 0.25rem;
  border-radius: 0.25rem;
  text-align: center;
  cursor: pointer;
  margin-top: 0.1rem;
  margin-left: 1rem;
  margin-right: 0.5rem;
  min-width: 20px;
  display: inline-block;
}
.btn-grabar:hover {
  background-color: #208372; /* verde más oscuro al pasar el ratón */
}

.btn-confirm {
  background-color: #cccccc;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 60px; /* Ajusta el valor según sea necesario */
  text-align: center;
}

.btn-confirm:hover {
  background-color: #aaaaaa;
}
.block {
  display: block;
}
.vista-select {
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%; /* Ajusta el ancho según tus necesidades */
  box-sizing: border-box; /* Asegura que el padding se incluya en el ancho total */
}
.dropdown {
  margin: 10px 0; /* Añadido margen superior e inferior */
  background: #21d0b3;
}
.consulta-wrapper {
  display: flex;
  align-items: center;
}
.consulta-textarea {
  flex: 1;
  background-color: #72f7e3;
}
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}
.popup button {
  margin-top: 10px;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  background-color: #3b82f6;
  color: white;
  cursor: pointer;
}
.popup button:hover {
  background-color: #2563eb;
}
.link {
  margin: 0 10px;
  color: #2eb99d;
  text-decoration: none;
  text-decoration: underline;
  /* font-weight: ;*/
}
.consumo-info {
  /*position: absolute;*/
  top: 0px;
  justify-content: center;
  font-size: 0.8em;
  color: #66cfba;
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 5px;
}
.text-gray {
  color: #6b7280; /* Puedes ajustar el código de color gris según tu preferencia */
}
.link-color {
  color: #56b71d; /* Puedes ajustar el código de color gris según tu preferencia */
}
</style>
