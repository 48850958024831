<template>
  <div class="ejemplos-container">
    <img :src="require('@/assets/gptconsulta.png')" alt="Logo" class="logo" />
    <div class="header-wrapper">
      <div class="header-container">
        <h3 class="titulo">Favoritos</h3>
        <input v-model="searchTerm" type="text" placeholder="Buscar" class="search-input" />
      </div>
    </div>
    <div class="scrollable-examples">
      <div v-for="(example, index) in filteredExamples" :key="index" class="example-row">
        <input
          type="checkbox"
          v-model="selectedExamples"
          :value="example"
          class="checkbox-margin"
        />
        <div class="example-text">{{ example }}</div>
        <button @click="usarEjemplo(example)" class="btn-usar">✅</button>
      </div>
    </div>
    <div class="button-container">
      <button
        v-if="selectedExamples.length > 0"
        @click="eliminarSeleccionados"
        class="btn-eliminar"
      >
        ELIMINAR
      </button>
      <button @click="volver" class="btn-volver">VOLVER</button>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import axios from 'axios';
import { useConsultaStore } from '@/stores/consulta';
import { useRouter, useRoute } from 'vue-router';
import { useAuthStore } from '@/stores/auth';

export default {
  name: 'EjemplosComponent',
  setup() {
    const consultaStore = useConsultaStore();
    const authStore = useAuthStore();
    const router = useRouter();
    const route = useRoute();

    const examples = ref([]);
    const selectedExamples = ref([]);
    //const vistaAlias = ref(route.query.vistaAlias || '');
    const vistaAlias = route.query.vistaAlias;
    const allExamples = ref([]);
    const searchTerm = ref('');

    const filteredExamples = computed(() => {
      if (!searchTerm.value) return examples.value;
      const lowercasedSearch = searchTerm.value.toLowerCase();
      return examples.value.filter((example) => example.toLowerCase().includes(lowercasedSearch));
    });

    const localConsulta = computed({
      get: () => consultaStore.localConsulta,
      set: (value) => consultaStore.setLocalConsulta(value),
    });

    const loadExamples = async () => {
      try {
        //const response = await axios.get('http://localhost:3000/api/ejemplos', {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/ejemplos`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        allExamples.value = response.data;
        //filterExamplesByVista();
      } catch (error) {
        console.error('Error al cargar los ejemplos:', error);
      }
    };

    /*
    const filterExamplesByVista = () => {
      if (vistaAlias.value) {
        examples.value = allExamples.value
          .filter((ejemplo) => ejemplo.nombre_vista === vistaAlias.value)
          .map((ejemplo) => ejemplo.ejemplo);
      } else {
        examples.value = allExamples.value.map((ejemplo) => ejemplo.ejemplo);
      }
    };*/

    onMounted(async () => {
      if (!authStore.isAuthenticated) {
        alert('Tu sesión ha expirado. Por favor, inicia sesión nuevamente.');
        router.push({ name: 'Login', query: { expired: 'true' } });
        return;
      }

      //const vistaAlias = route.query.vistaAlias;

      const vista = route.query.vista;
      //alert(`Nombre de Vista al montar: ${vista}`);
      // alert(`Todas las query params: ${JSON.stringify(route.query)}`);

      try {
        //const response = await axios.get('http://localhost:3000/api/ejemplos', {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/ejemplos`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        allExamples.value = response.data;

        if (vista) {
          examples.value = allExamples.value
            .filter((ejemplo) => ejemplo.nombre_vista === vista)
            .map((ejemplo) => ejemplo.ejemplo);
        } else {
          examples.value = allExamples.value.map((ejemplo) => ejemplo.ejemplo);
        }

        //alert(`Ejemplos cargados para vista ${vistaAlias}: ${JSON.stringify(examples.value)}`);
      } catch (error) {
        console.error('Error al cargar los ejemplos:', error);
        alert('Error al cargar los ejemplos');
        await loadExamples();
      }
    });

    const usarEjemplo = (example) => {
      consultaStore.setLocalConsulta(example);
      router.push('/consulta');
    };

    const volver = () => {
      router.push('/consulta');
    };

    const eliminarSeleccionados = async () => {
      console.log('Eliminando ejemplos seleccionados:', selectedExamples.value);
      try {
        const response = await axios.post(
          //'http://localhost:3000/api/eliminar-ejemplos',
          `${process.env.VUE_APP_API_URL}/api/eliminar-ejemplos`,
          {
            ejemplosAEliminar: selectedExamples.value,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );

        if (response.data.success) {
          console.log(response.data.message);
          // Actualizar la lista de ejemplos localmente
          examples.value = examples.value.filter(
            (example) => !selectedExamples.value.includes(example)
          );
          selectedExamples.value = [];
        } else {
          throw new Error(response.data.message || 'Error al eliminar ejemplos');
        }
      } catch (error) {
        console.error('Error:', error);
        alert('Error al eliminar los ejemplos');
      }
    };

    /*
    watch(
      () => route.query.vistaAlias,
      (newVistaAlias) => {
        vistaAlias.value = newVistaAlias || '';
        //filterExamplesByVista();
      }
    );*/

    return {
      localConsulta,
      examples,
      selectedExamples,
      usarEjemplo,
      volver,
      eliminarSeleccionados,
      allExamples,
      vistaAlias,
      searchTerm,
      filteredExamples,
    };
  },
};
</script>

<style scoped>
.titulo {
  width: 50%;
  text-align: center;
  font-size: 16px;
  color: #6b7280;
  font-weight: bold;
  /*margin-left: 50x;*/
  /*margin: 0;*/
}

.ejemplos-container1 {
  max-width: 800px;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 2px;
  box-sizing: border-box;
}

.ejemplos-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 2px;
  box-sizing: border-box;
}

.logo {
  width: 100px;
  margin-bottom: 5px;
}

.header-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%; /* Ajusta este valor para controlar el ancho del contenedor del encabezado */
  max-width: 600px; /* Ajusta este valor según tus necesidades */
}

.search-input {
  width: 50%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  margin-left: auto; /* Empuja el input hacia la derecha */
  margin-right: 50px;
}

.scrollable-examples {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 300px;
  overflow-y: auto;
  width: 100%;
  padding: 2px;
  box-sizing: border-box;
}

.example-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 500px;
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 10px;
}

.example-text {
  flex: 1;
  margin-right: 10px;
}

.btn-usar {
  /*background-color: #21d0b3;*/
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-usar:hover {
  background-color: #208372;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.btn-volver {
  background-color: #6b7280;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-left: 10px;
}

.btn-volver:hover {
  background-color: #4b5563;
}

.checkbox-margin {
  margin-left: 10px;
  margin-right: 10px;
  padding: 5px;
}

.btn-eliminar {
  background-color: #e3342f;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-right: 10px;
}

.btn-eliminar:hover {
  background-color: #cc1f1a;
}
</style>
